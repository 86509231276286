.modal, .js__report-modal, .js__register-modal, .js__newsletter-subscription-modal {
    position: absolute;
    z-index: 10000; /* 1 */
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
}

.modal.is-visible, .js__report-modal.is-visible, .js__register-modal.is-visible, .js__newsletter-subscription-modal.is-visible {
  visibility: visible;
}

.modal-overlay {
position: fixed;
z-index: 10;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: hsla(0, 0%, 0%, 0.5);
visibility: hidden;
opacity: 0;
transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay, .js__report-modal.is-visible .modal-overlay,
.js__register-modal.is-visible .modal-overlay, .js__newsletter-subscription-modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.map-modal-wrapper {
  position: fixed;
  z-index: 9999;
  top: 5%;
  left: 50%;
  width: 80%;
  height: 80%;
  margin-left: -40%;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}

.map-modal-transition,  {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .map-modal-transition, .js__report-modal.is-visible .map-modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.modal-header {
  padding: 1em;
}

.map-modal-content {
  height: 100%;
}

.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  cursor: pointer;
  color: #aaa;
  background: none;
  border: 0;
}

.modal-close:hover {
  color: #777;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

.map-modal-content > *:first-child {
  margin-top: 0;
}

.map-modal-content > *:last-child {
  margin-bottom: 0;
}

.modal-content {
  height: 100%;
  padding: 32px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  & label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  & button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 16px;
  }
  & textarea {
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box; 
    height: 100px;
  }
  & input {
    width: 100%;
  }
}

.modal-wrapper {
  position: fixed;
  text-align: left;
  z-index: 9999;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
}
@media(max-width: $md) {
 .modal-wrapper {
   width: 100%;
 }
}


.modal-transition {
  transition: all 0.3s 0.12s;
  opacity: 0;
}

.modal.is-visible .modal-transition, .js__report-modal.is-visible .modal-transition,
.js__register-modal.is-visible .modal-transition, .js__newsletter-subscription-modal.is-visible .modal-transition {
  opacity: 1;
}