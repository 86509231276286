.static {
  @extend %section;
}

/* MAIN */
.static__title {
  @extend %heading--tabs;
  margin-top: 0;
}

.static__subtitle {
  @extend %heading--privacy;
  font-weight: 700;
}

.static__text {
  @extend %text--details;
  margin-bottom: 1rem;
}

.static__list {
  margin-top: -1rem;
  margin-bottom: 1rem;

  & li {
    @extend %text--details;

    &:before {
      display: inline-block;
      content: '-';
      margin-right: 0.4rem;
    }
  }
}

/* ASIDE */
.static__nav {
  @extend %card;
  @extend %shadow;
  @extend %box;

  background-color: $light-grey;
  margin-bottom: 2rem;

  &-item {
    margin-bottom: 0.2rem;

    &.active a {
      color: $black;
    }
  }
}

.static__contact {
  &-image {
    & img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      display: block;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $blue;
    padding: 1rem;

    & h2 {
      display: inline-block;
      font-size: 120%;
      line-height: 1.2;
      color: $light-text;

      @media (min-width: $xxs) {
        font-size: 150%;
      }

      @media (min-width: $sm) {
        font-size: 110%;
      }

      @media (min-width: $md) {
        font-size: 120%;
      }

      @media (min-width: $lg) {
        font-size: 150%;
      }
    }
  }

  &-icon {
    padding-left: 1rem;

    & svg {
      width: 40px;
      height: 30px;
      fill: $white;

      @media (min-width: $sm) {
        width: 40px;
        height: 30px;
      }

      @media (min-width: $lg) {
        width: 60px;
        height: 50px;
      }
    }
  }
}

.static__row {
  margin: 1rem 0;
}

.static__link-big {
  color: $orange-text;
  font-weight: bold;
}

.static__whitespace {
  white-space: pre-wrap;
}