/* Offers section MAIN */
.offers__list {
  margin-top: 2rem;
  @extend .row;
  align-items: stretch;
}

.offers__item {
  @extend .col-md-4;
  margin-bottom: 10px;
  text-align: center;

  @media (min-width: $sm) {
    text-align: left;
  }
}

.offers__item-card {
  @extend %card,
  %shadow;
  @extend %card--grey;
  @extend %box;
  height: 100%;
  border-radius: 10px;
}

.offers__label {
  @extend %heading--card;
  margin-bottom: 0.5rem;

  @media (min-width: $md) {
    margin-bottom: 0.8rem;
  }
}

.offers__criteria-count {
  font-weight: 500;
}

.offers__criteria-item {
  font-size: 85%;
  margin-bottom: 0.2rem;
  text-align: center;

  & a {
    display: inline-block;
    min-height: 48px;
  }

  @media (min-width: $xxs) {
    font-size: 90%;
  }

  @media (min-width: $sm) {
    font-size: 80%;
    text-align: left;

    & a {
      display: inline;
      min-height: auto;
    }
  }

  @media (min-width: $md) {
    font-size: 90%;
  }

  @media (min-width: $lg) {
    font-size: 100%;
  }
}

/* Offers section SIDEBAR */
.offers--side {

  .offers__list {
    flex-direction: column;
  }

  .offers__item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}