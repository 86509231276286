%header__share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (max-width: $xs) {
    margin-bottom: 2rem;
  }

  @media (min-width: $xs) {
    align-items: flex-end;
    flex-direction: row;
    margin-top: 1rem;
  }

  @media (min-width: $sm) {
    margin-top: 0;
  }

}

%header__share-button {
  position: relative;
  top: -4px;
  margin-top: 2rem;

  @media (min-width: $xs) {
    margin-top: 0;
  }

  & button {
    @extend %btn;
    @extend %btn__share;

    @media (min-width: $xs) {
      margin: 0 0 0;
    }
  }
}

%icon-share {
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
}

%overview__image {
  margin-bottom: 2rem;
  display: none;

  @media (min-width: $sm) {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  @media (min-width: $md) {
    display: block;
    float: none;
    margin-right: 0;
  }

  & img {
    display: block;
    width: 100%;

    @media (min-width: $md) {
      width: auto;
    }
  }
}

/* OVERVIEW PAGE */
.overview {
  &__header {
    & .bcrumb {
      @extend %header__share;
    }

    &-share-button {
      @extend %header__share-button;
    }

    &-icon-share {
      @extend %icon-share;
    }
  }

  &__hero {
    @extend %section;
    background-color: $smoke-box;
    padding: 3rem 0;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: $md) {
      flex-direction: row;
      padding: 3rem 0;
    }

    &-img {
      & img {
        width: 100%;
      }

    }

    &-action {
      flex: 0 0 auto;
      padding: 1rem;

      @media (min-width: $sm) {
        padding: 0rem;
      }

      @media (min-width: $md) {
        flex: 0 0 500px;
      }

      &-title {
        width: auto;
        font-size: 2rem;
        line-height: 1.2;
        color: $blue;
        margin: 0 auto 2rem;

        @media (min-width: $xs) {
          width: 350px;
        }
      }
    }
  }

  &__title {
    @extend %heading--static;
  }

  &__container {
    @extend %section;
    margin: 2rem 0 3rem;

    @media (min-width: $md) {
      margin: 3rem 0 5rem;
    }
  }

  &__description {

    display: block;

    @media (min-width: $md) {
      display: flex;
    }

    &--2 {
      @media (min-width: $md) {
        flex-direction: row-reverse;
      }
    }

    &-img {
      @extend %overview__image;
    }

    &-info {
      padding: 0;
      display: flex;
      flex-flow: column;
      min-width: 50%;

      @media (min-width: $md) {
        padding: 0 1rem;
      }

      &-title {
        @extend %heading--card;
        margin-bottom: 1rem;
      }

      &-text {
        @extend %card__text;
        margin-top: 16px;
        font-size: 1.1em;

        &--2 {
          color: $blue;
        }
      }

      &-button {
        flex-grow: 1;
        position: relative;
        margin-top: 3em;
        text-align: center;

        & a {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #fa8526;
          color: #fff;
        }

        & a:hover {
          color: #fff;
        }
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8rem 0 6rem;

    &-info {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &-title {
      font-size: 25px;
      line-height: 1.2;
      color: $blue;
    }

    &-action {
      padding: 1.5rem 1rem;
      margin-left: -1rem;
    }

    &-icon {
      width: 30px;
      height: 25px;
      vertical-align: middle;
      fill: $orange;
    }

    &-number {
      font-size: 20px;
      font-weight: 600;
    }

    &-email {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

/* PRINT PAGE */
.print {
  &__header {
    & .bcrumb {
      @extend %header__share;
    }

    &-share-button {
      @extend %header__share-button;
    }

    &-icon-share {
      @extend %icon-share;
    }
  }

  &__title {
    @extend %heading--static;
  }

  &__description {
    display: block;
    margin: 2rem 0 3rem;

    @media (min-width: $md) {
      display: flex;
      margin: 3rem 0 5rem;
    }

    &-img {
      @extend %overview__image;
    }

    &-info {
      padding: 0;

      @media (min-width: $md) {
        padding: 0 1rem;
      }

      &-title {
        @extend %heading--card;
        margin-bottom: 1rem;
      }

      &-text {
        @extend %card__text;
      }
    }
  }

  /* product cards */
  &__products {
    margin-bottom: 5rem;

    @media (min-width: $md) {
      margin-bottom: 10rem;
    }

    &-list {
      @extend .row;
    }

    &-item {
      @extend .col-md-4;
      margin-bottom: 1rem;
    }

    &-card {
      @extend %card,
      %card--grey,
      %shadow;
      text-align: center;

      &-header {
        @extend %card__header--blue,
        %card__header;
      }

      &-title {
        @extend %card__title--white;
      }

      &-body {
        @extend %box;
      }

      &-img {
        position: relative;
        padding: 1rem 0;
        min-height: 150px;
        justify-content: center;
        align-items: center;
        height: auto;

        @media(min-width: $sm) {
          height: 27rem;
        }

        & svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;


          &.hide {
            visibility: hidden;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &-details {
        @extend %card__text;
        width: 100%;
        font-size: 1em;
        margin-top: 12px;

        @media (min-width: $sm) {
          width: 100%;
          font-size: 0.9em;
        }

        @media (min-width: $md) {
          font-size: 0.9em;
        }

        &.hide {
          visibility: hidden;
        }
      }

      &-price {
        @extend %card__price;
      }

      &-action {
        display: inline-block;
      }

      &-button {
        @extend %btn;
        @extend %btn--orange;
      }
    }
  }
}