

/* SEARCH FORM */
.section--search-form {
  @extend %section;
  width: 100%;
  margin: 2rem auto 0;
  @media (min-width: $xxs) {
    width: 90%;
  }
  @media (min-width: $xs) {
    width: 90%;
  }
  @media (min-width: $sm) {
    width: 70%;
  }
  @media (min-width: $md) {
    margin: 2rem auto 3rem;
    width: auto;
  }
  & .simple-form {
    justify-content: space-between;
  }

  & .simple-form__submit-button {
    margin-left: auto;
    text-transform: uppercase;
  }
}

/* SEARCH HEADER */
.search__header {
  display: block;
  color: $dark-grey;
  border-bottom: 1px solid $dark-grey;
  text-align: center;

  @media (min-width: $md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
}

.search__title {
  @extend %heading--search;
}

.search__results-title {
  line-height: 3em;
  font-size: 1.3em;
}

.search__switch {
  display: block;
  margin-bottom: 1rem;
  @media (min-width: $md) {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    margin-bottom: 0;
  }
  @media (min-width: $lg) {
    margin-right: 2rem;
  }
}

.filter__label {
  display: inline-block;
  font-size: 90%;
  margin-right: 1rem;
  @media (min-width: $md) {
    text-align: right;
    margin-right: 0.7rem;
    font-size: 100%;
  }
  @media (min-width: $lg) {
    width: 140px;
    margin-right: 1.2rem;
  }
}

.search__sort {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  @media (min-width: $md) {
    flex-basis: 40%;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  @media (min-width: $lg) {
    flex-basis: 35%;
  }
}

.sort__select {
  &:focus {
    outline: none;
  }
  @media (min-width: $md) {
    flex-basis: 100px;
  }
  @media (min-width: $lg) {
    flex-basis: 200px;
  }
}

/* MAP VIEW SWITCH */
.switch {
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: $md) {
    align-items: center;
  }
}
.switch__input {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 14px;
}

.switch__input input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border: 1px solid $black;
  border-radius: 20px;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid $black;
  border-radius: 10px;
  left: -2px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .switch__slider {
  background-color: #38ab32;
}

input:focus + .switch__slider {
  box-shadow: 0 0 1px #38ab32;
}

input:checked + .switch__slider:before {
  transform: translateX(16px);
}

/* SERVICE PROVIDERS LIST */
.service-provider__list {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $dark-grey
}

.btn-filter {
  width: 100%;
} 

.btn-filter-mobile {
  color: #6c757d;
  border: 1px solid #6c757d;
  // padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  display: none;
  
  span {
    display: inline-block;
  }

  svg {
    fill: #6c757d;
    width: 30px;
    height: 30px;
  }
}

/* PAGINATION */
.pagination {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pagination__item {
  display: flex;
  color: $dark-grey;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 25px;
  font-size: 1em;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  border-radius: 3px;
}
.pagination__item.active {
  background-color: $dark-grey;
  & .pagination__link {
    color: $light-text;
  }
}
.pagination__item-symbol {
  position: relative;
  top: -2px;
  font-size: 200%;
  border: none;
}
.pagination__link {
  padding-top: 1px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .row-search {
    flex-flow: column-reverse;
  }
  .btn-filter-mobile {
    display: flex;
    margin-bottom: 30px;
  }
  .search-filters {
    display: none;
  }
  .search-filters.active {
    display: block;
  }
}