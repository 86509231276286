.user {
  &__main-title {
    @extend %heading--card--lg;
  }
}

#mainImageImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

.select-category {
  max-width: 80%;

  & option:disabled {
    color: $blue;
    background-color: #eee;
    font-weight: 600;
  }
}

.category-list {
  margin-bottom: 0.5em;
}

.unsaved-changes-highlight {
  box-shadow: 0px 0px 10px 0px $blue;
}

.unsaved-changes-btn {
  background-color: $blue;
  color: $white;
  padding: 2px 0.5rem;
  position: fixed;
  right: 16px;
  bottom: 0px;
  box-shadow: 0px 0px 10px 0px $blue;
  border-radius: 10px 10px 0 0;
  font-size: 0.8rem;
  text-transform: none;
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.unsaved-changes-btn:focus {
  outline: none;
}
#upload_widget_opener {
  display: none;
}
.cloudinary-button {
  text-decoration: none !important;
  font-size: 100% !important;
  line-height: inherit !important;
  height: auto !important;
  font-weight: normal !important;
  padding: 0.3rem 0.5rem !important;
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
  transition: 0.1s all ease-in !important;
  margin: 0.5em 0;
  &:hover {
    cursor: pointer !important;
    background-color: $white !important;
    color: $orange !important;
    box-shadow: none !important;
  }
  background-color: $dark-button !important;
  color: $white !important;
}