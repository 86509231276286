/* SERVICE PROVIDER CARD */
.service-provider-card {
  @extend %shadow;
  display: block;
  background-color: $grey-box;
  margin-bottom: 2rem;
  border-radius: 10px;
  position: relative;

  @media (min-width: $md) {
    display: flex;
    flex-basis: 50%;
  }

  &__personal {
    display: flex;
    flex-direction: column;

    @media (min-width: $xs) {
      flex-direction: row;
    }

    @media (min-width: $md) {
      flex-basis: 50%;
    }
  }

  &__avatar {
    margin-right: 0rem;

    @media (min-width: $xs) {
      margin-right: 1rem;
    }


    & img {
      width: 40%;
      margin: 1rem auto;
      display: block;
      max-height: 200px;
      width: 200px;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;

      @media (min-width: $xs) {
        width: 130px;
        height: 130px;
        border-radius: 0;
        margin: 0;
      }

      @media (min-width: $md) {
        height: 200px;
        width: 200px;
      }
    }
  }


  &__link {
    & img {
      border-radius: 10px;

      @media(min-width: $xs) {
        border-radius: 10px 0 0 0;
      }

      @media(min-width: $md) {
        border-radius: 10px 0 0 10px;
      }
    }
  }

  &__name {
    @extend %heading--card;
    margin-bottom: 0.7rem;
  }

  &__tags-item {
    font-size: 90%;

    & a {
      text-decoration: underline;
    }
  }

  &__service {
    font-size: 90%;
    color: $black-text;
  }

  // KE: begin
  &__info-newsletter-subscription {
    flex-grow: 1;
    display: block;
    margin: 0.7rem 0;
    text-align: left;
  }

  // KE: end
  &__info {
    flex-grow: 1;
    display: block;
    padding: 0.7rem 0;
    text-align: center;

    @media (min-width: $xs) {
      text-align: left;
    }

    @media (min-width: $md) {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem 0;
    }
  }

  &__tags {
    margin-top: 0.1rem;
    margin-bottom: 1rem;

    @media (min-width: $md) {
      margin-bottom: 0;
    }
  }

  &__tags-item {
    display: inline-block;

    &:after {
      content: ',';
    }

    // & li:first-child:after,
    &:last-child:after {
      content: '';
    }

    & a {
      color: $blue;
    }
  }

  &__item-distance {
    display: inline-block;
    margin-bottom: 0.2rem;
    color: $orange;

    & svg {
      width: 12px;
      height: 12px;
      fill: $orange;
    }
  }

  &__location {
    font-size: 90%;
  }



  // KE: end
  &__quote-newsletter-subscript {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;

  /*  @media (max-width: ($xxs - 1)) {
      padding:0rem;
    }

    @media (min-width: $xxs) {
      padding:0;
    }

    }*/
  }


  &__quote {
    flex-basis: 50%;
    padding: 0.5rem 1rem 1rem;

    & q {
      height: 100%;
      font-size: 95%;
      font-style: italic;
      line-height: 1.6;
      display: block;
      padding: 0.7rem 0.2rem 0;
      border-left: none;
      text-align: center;

      @media (min-width: $xxs) {
        padding: 1rem 1rem 0;
      }

      @media (min-width: $xs) {
        text-align: left;
      }

      @media (min-width: $md) {
        padding: 0.5rem 1rem;
        border-left: 1px solid $dark-grey;
        border-top: none;
      }
    }
  }

  &__sub {
    &-lvl1 {
    }

    &-lvl2 {
      outline: solid 3px $blue;
    }

    &-lvl3 {
      box-shadow: 0 0 0pt 2pt $orange;

      &::before {
        color: white;
        border: 1.5em solid $orange;
        border-top-width: 1.5em;
        border-bottom-color: transparent;
        content: "";
        position: absolute;
        display: block;
        right: 8px;
      }
    }
  }
}

