.slick-dotted {
  &.slick-slider {
    margin-bottom: 2.5rem;
    @media (min-width: $md) {
      margin-bottom: 0;
    }
  } 
}

.slick-dots {
  position: relative;
  top: -7px;
  z-index: 100;
  display: flex;
  & li {
    height: 10px;
    flex: 1 1 auto;
    padding: 0; 
    margin: 0;
    margin-right: 2px;
    background-color: $strong-grey !important;
    &:last-child {
      margin: 0;
    }
    &.slick-active {
      background-color: transparent !important;
      background-image: linear-gradient(-90deg, rgba($white, 0.5), rgba($dark-grey, 0.9));
    }
  }
  & button {
    height: 10px !important;
    width: 100% !important; 
    &:after,
    &:before {
      content: '' !important;
    }
  }
}

.slick-next {
  display: none;
}