.subscriptions {
  &-plans {
    &__title {
      @extend %heading--static;
    }

    &__subtitle {
      text-align: center;
      font-size: 1.1em;
      margin-bottom: 3em;

      & a {
        color: $blue;
      }
    }

    &__list {
      @extend .row;
      margin-top: 1.5rem;
      margin-bottom: 5rem;
    }

    &__item {
      @extend .col-md-4;
      text-align: center;
      margin-bottom: 2rem;

      @media (min-width: $sm) {
        margin-bottom: 0;
      }

      &__active {
        & .subscriptions-plans__card-body {
          border-width: 0 3px 3px 3px;
          border-color: $orange;
          border-style: solid;
        }

        & .subscriptions-plans__card-header {
          background-color: $orange;
        }

        &::before {
          content: 'Aktives Abonnement';
          position: absolute;
          top: -23px;
          text-align: center;
          width: 100%;
          left: 0;
          color: $orange;
        }
      }

      &__bestseller {
        background-color: $orange;

        &::before {
          content: 'Bestseller';
          position: absolute;
          top: -23px;
          text-align: center;
          width: 100%;
          left: 0;
          color: $orange;
        }
      }

      &__cancel {
        & .subscriptions-plans__card-body {
          border-width: 0 3px 3px 3px;
          border-color: $orange;
          border-style: solid;
        }

        & .subscriptions-plans__card-header {
          background-color: $orange;
        }

        &::before {
          content: 'Aktiv bis 'attr(data-cancelAt);
          position: absolute;
          top: -23px;
          text-align: center;
          width: 100%;
          left: 0;
          color: $orange;
        }
      }
    }

    &__card {
      @extend %card--grey,
      %card,
      %shadow;
    }

    &__card-header {
      @extend %card__header--blue;
      border-radius: 10px 10px 0 0;
    }

    &__card-header-title {
      @extend %card__title--white;
    }

    &__card-body {
      padding: 0 1rem;
      border-radius: 0 0 10px 10px;

      @media (min-width: $sm) {
        padding: 0 0.5rem;
      }

      @media (min-width: $md) {
        padding: 0 0.5rem;
      }

      @media (min-width: $lg) {
        padding: 0 1rem;
      }
    }

    &__card-price {
      @extend %card__price;
    }

    &__card-actions {
      width: 150px;

      @media (min-width: $sm) {
        height: 80px;
      }

      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }

    &__action-button {
      @extend %btn;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $sm) {
        font-size: 0.9rem;
      }

      @media (min-width: $md) {
        font-size: 1rem;
      }
    }

    &__action-button--orange {
      @extend %btn--orange;
    }

    &__action-button--white {
      @extend %btn--white;
    }

    &__icon-arrows {
      @extend %icon__arrows;
    }

    &__icon-arrows--grey {
      fill: $strong-grey;
    }

    &__features {
      padding: 2rem 0 1rem;
      margin: 0 auto;

      @media (min-width: $sm) {
        padding: 1rem 0;
      }

      @media (min-width: $md) {
        padding: 2rem 0;
      }
    }

    &__feature-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      width: 100%;

      @media (min-width: $xxs) {
        flex-direction: column;
      }

      @media (min-width: $sm) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      @media (min-width: $md) {
        flex-direction: row;
      }

      &.hide {
        display: none;

        @media (min-width: $sm) {
          display: flex;
        }
      }
    }

    &__feature-item-text {
      display: inline-block;
      font-size: 0.9rem;
      margin-left: 0.5rem;

      @media (min-width: $xs) {
        font-size: 0.9rem;
      }

      @media (min-width: $sm) {
        font-size: 0.8rem;
      }

      @media (min-width: $md) {
        font-size: 0.8rem;
      }

      @media (min-width: $lg) {
        font-size: 0.9rem;
      }
    }

    &__check-icon {
      display: inline-block;
      margin-bottom: 0.3rem;
      width: 25px;
      height: 25px;
      fill: red;

      @media (min-width: $xxs) {
        width: 20px;
        height: 20px;
        margin-right: 0.2rem;
      }
    }

    &__check-icon--checked {
      fill: $green;
    }

    &__dropdown {
      position: relative;
      padding-bottom: 1rem;

      @media (min-width: $sm) {
        display: none;
      }

      &-more {
        font-size: 0.9rem;
        font-weight: bold;
        text-decoration: underline;
        color: $orange;

        &:hover {
          cursor: pointer;
        }
      }

      &-icon {
        display: inline-block;
        width: 20px;
        height: 20px;

        position: absolute;
        top: 3px;
        right: 10px;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
  }

  &-details {
    display: none;

    @media (min-width: $sm) {
      display: block;
    }
  }

  &__comparisontable {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 5rem;

    &-header {
      & li {
        background-color: $blue;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 700;
        padding: 12px 0 !important;
        margin-bottom: 1rem;
        margin-right: 1rem;
        color: $white;
        border: none !important;
      }

      & li:first-child,
      & li:last-child {
        margin-right: 0;
      }
    }

    &-row {
      list-style: none;
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      &--blue {
        background-color: $blue !important;

        & li {
          border: none !important;
        }

        & .legend {
          color: $white;
          background-color: $blue;
        }
      }

      &--last {
        & li {
          border: none !important;
        }
      }

      & li {
        flex: 1;
        padding: 4px;
        text-align: center;
        border-bottom: 1px solid $blue;

        & svg {
          vertical-align: middle;
        }
      }

      & li.legend {
        flex: 0 0 300px;
        border: none;
        font-size: 0.9rem;
        border-bottom: 1px solid transparent;
        text-align: left;
        padding-left: 10px;

        @media (min-width: $md) {
          flex: 0 0 450px;
        }
      }

      &:first-of-type li {
        text-align: center;
      }

      &:last-of-type li {
        text-align: center;
        border-bottom: none;
      }

      &:first-of-type li.legend,
      &:last-of-type li.legend {
        background: transparent;
      }

      & .subscriptions-plans__card-price {
        font-size: 1rem;

        @media (min-width: $md) {
          font-size: 1.3rem;
        }
      }

      & .subscriptions-plans__card-actions {
        width: 80%;

        @media (min-width: $sm) {
          width: 90%;
        }

        @media (min-width: $md) {
          width: 80%;
        }
      }
    }
  }
}

.payment-side .subscriptions-plans__feature-item {
  @media (min-width: $sm) {
    flex-direction: row;
  }
}