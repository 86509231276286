body{
  min-height: 100vh;
}
.footer {
  position:sticky;
  top:100%;
  color: $light-text;
}

.footer__main {
  background-color: $dark-box;
  // background-image: linear-gradient(rgba($dark-box, 0.9), rgba(0,0,0, 0.5));
}

.footer__main-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer__nav {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: $md) {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }
}

.footer__nav-section {
  @media (min-width: $xs) {
    margin-bottom: 1rem;
  }
}

.footer__nav-title {
  font-size: 120%;
  font-weight: 500;
  color: $white-heading;
  padding-bottom: 0.7rem;
  letter-spacing: 1px;

  @media (min-width: $md) {
    padding-bottom: 0.5rem;
  }
}

.footer__nav-list {
  margin-bottom: 2rem;

  @media (min-width: $xs) {
    margin-bottom: 0;
  }
}

.footer__nav-item {
  font-size: 90%;
  font-weight: 400;
  padding-bottom: 0.3rem;
  color: $grey;

  & a {
    display: inline-block;
    min-height: 48px;
  }

  @media (min-width: $sm) {
    & a {
      display: inline;
      min-height: auto;
    }
  }
}

.footer__logo {
  display: inline-block;
  width: 250px;
  margin-top: 2rem;

  & img {
    width: 100%;
  }

  & span {
    font-weight: 100;
  }

  @media (min-width: $md) {
    margin-top: 0;
  }
}

.footer__nav-social {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  @media (min-width: $md) {
    justify-content: flex-start;
  }
}

.footer__newsletter form {
  display: flex;
  flex-direction: column;
}

.footer__newsletter-email {
  width: 100%;
  padding: 0.4rem 0 0.4rem 0.5rem;
  border: none;

  &:hover {
    background-color: #f7f7f7;
  }

  &:focus {
    // outline: 2px solid $orange;
  }
}

.footer__newsletter-button {
  @extend %btn;
  @extend %btn--orange;
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 7px;
  transition: 0.05s all linear;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0.5rem;
}

.footer__nav-social-item {
  margin-right: 1rem;
  margin-left: 1rem;

  @media (min-width: $md) {
    margin-right: 1.5rem;
    margin-left: 0;
  }
}

.social--icon {
  width: 30px;
  height: 30px;
  fill: $grey;

  &:hover {
    fill: $orange;
  }
}

.footer__copy {
  background-color: $grey-box;
  color: $dark-text;
  font-size: 75%;
  padding: 0.3rem 0;
  text-align: center;

  @media (min-width: $md) {
    text-align: left;
  }
}