.accordion {
  margin: auto;
  text-align: center;
}

.accordion .no-select, .accordion h3 {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

.accordion h3 {
  color: #676767;
  font-size: 1.2em;
  line-height: 34px;
  padding: 15px 15px 0;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: .2s;
}

.accordion p {
  line-height: 1.6;
  letter-spacing: -0.5px;
  color: #676767;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  max-height: 250px;
  will-change: max-height;
  contain: layout;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 50px 0 15px;
  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  z-index: 2;
  white-space: pre-wrap;
}

.accordion ul {
  text-align: left;
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}

.accordion ul li {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0,0,0,0.1);
  -webkit-tap-highlight-color: transparent;
  border-radius: 10px;
}

.accordion ul li + li {
  margin-top: 15px;
}

.accordion ul li:last-of-type {
  padding-bottom: 0;
}

.accordion ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 28px;
  right: 15px;
}

.accordion ul li i:before, .accordion ul li i:after {
  content: "";
  position: absolute;
  background-color: #fa8526;
  width: 3px;
  height: 9px;
}

.accordion ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}

.accordion ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}

.accordion ul li input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  touch-action: manipulation;
}

.accordion ul li input[type=checkbox]:checked ~ h3 {
  color: #676767;
}

.accordion ul li input[type=checkbox]:checked ~ p {
  max-height: 0;
  transition: .3s;
  opacity: 0;
}

.accordion ul li input[type=checkbox]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}

.accordion ul li input[type=checkbox]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}