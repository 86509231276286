%heading--hero {
  font-size: 220%;
  text-align: center;
  line-height: 1.1;
  color: $white-heading;
  @media (min-width: $xxs) {
    font-size: 230%;
  }
  @media (min-width: $xs) {
    font-size: 280%;
  }
  @media (min-width: $sm) {
    font-size: 320%;
  }
  @media (min-width: $md) {
    font-size: 350%;
  }
  @media (min-width: $lg) {
    font-size: 420%;
  }
}

%heading--orange {
  color: $orange-heading !important;
}

%heading--section {
  font-size: 200%;
  line-height: 1.2;
  text-align: center;
  color: $grey-heading;
  margin: 1rem 0.5rem 0.5rem;
  @media (min-width: $sm) {
  font-size: 220%;
  line-height: inherit;
  }
  @media (min-width: $md) {
  font-size: 250%;
  }
}

%heading--card {
  font-size: 120%;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.3;
  color: $blue-heading;
  @media (min-width: $xs) {
    font-size: 130%;
  }
  @media (min-width: $sm) {
    font-size: 100%;
  }
  @media (min-width: $md) {
    font-size: 110%;
  }
  @media (min-width: $lg) {
    font-size: 120%;
  }
}

%heading--static {
  font-size: 19px;
  color: $dark-grey;
  text-align: center;
}

%heading--card--lg {
  font-size: 140%;
  @media (min-width: $md) {
    font-size: 150%;
  }
  @media (min-width: $lg) {
    font-size: 170%;
  }
}

%heading--card-upper {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 130%;
  font-weight: 500;
  color: $blue-heading;
  @media (min-width: $xs) {
    font-size: 150%;
  }
  @media (min-width: $sm) {
    font-size: 130%;
  }
  @media (min-width: $md) {
    font-size: 150%;
  }
}

%heading--search {
  font-size: 1.3rem;
  flex-grow: 1;
  text-align: center;
  margin: 1rem 0;
  color: $dark-grey;
  @media (min-width: $xxs) {
    font-size: 1.6rem;
  }
  @media (min-width: $xs) {
    font-size: 1.8rem;
  }
  @media (min-width: $md) {
    margin: 0.5rem 0;
    text-align: left;
    font-size: 1.6rem;
  }
  @media (min-width: $lg) {
    font-size: 1.8rem;
  }
}

%heading--tabs {
  font-size: 130%;
  font-weight: 700;
  color: $dark-grey;
  margin-top: 1.2rem;
  margin-bottom: 0.6rem;
  @media (min-width: $md) {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  }
}

%heading--labels {
  font-size: 100%;
  font-weight: 500;
  color: $dark-grey;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  @media (min-width: $md) {
    margin-top: 0.8rem;
    margin-bottom: 0.3rem;
  }
}

%heading--privacy {
  font-size: 100%;
  color: $blue;
  margin: 0.2rem 0;
}
