.navbar {
    display: block;
    height: 50px;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    color: $white;
    background-color: #7f7f7f;
    overflow: hidden;
    transition: height 0s 0.5s, background-color 0.3s 0.5s;
    padding: 0;
    &>.navbar__logo {
        margin: 8px auto;
        display: block;
        left: 0;
        right: 0;
        position: absolute;
    }
    @media (min-width: $md) {
        background-color: rgba($dark-grey, 0.98);
        &>.navbar__logo {
            display: none;
        }
        transform: none;
        transition: none;
        display: block;
        position: absolute;
        padding-top: 0;
        background: url('/images/page/header/Mobile_Wellness-Massage.jpg') no-repeat 0% 70%/cover;
        height: 280px;
    }
}

.page--welcome .navbar {
    @media (min-width: $md) {
        background: none;
    }
}

.page--welcome main {
    margin-top: 50px;
    @media (min-width: $md) {
        margin-top: 0px;
    }
}

// .page--welcome .navbar > .navbar__logo {
//   display: none;
// }
.navbar.navbar--is-expanded {
    height: 100vh;
    z-index: 10000;
    background-color: transparent;
    transition: height 0s;
    &>.navbar__logo {
        display: none;
    }
}

.navbar__menu {
    position: absolute;
    z-index: 10000;
    top: 1rem;
    left: 1rem;
}

.navbar__content {
    position: relative;
    z-index: -10;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    background-color: $white;
    pointer-events: none;
    transform: translateX(-100vw);
    transition: transform 0.5s ease-out, z-index 0s 0.5s;
    height: 100%;
    display: flex;
    @media (min-width: $md) {
        height: 90px;
        display: flex;
        z-index: 101;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 3rem;
        margin: 0;
        background-color: rgba(135, 135, 135, 0.93);
        position: fixed;
        top: 0px;
        width: 100%;
        transform: scale(1);
        pointer-events: all;
        opacity: 1;
    }
    @media (min-width: $lg) {}
}

@media (max-width: $md) {
    .navbar::after {
        content: ' ';
        background: rgba(44, 44, 44, 0.95);
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        transition: opacity 0.5s, height 0s 0.5s;
        height: 0vh;
    }
    .navbar--is-expanded.navbar::after {
        height: 100vh;
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.navbar-content--is-visible {
    pointer-events: all;
    display: flex;
    z-index: 1;
}

.navbar__content.navbar-content--is-visible {
    transform: translateX(0vw) scale(1);
    width: 85%;
    transition: transform 0.5s ease-out;
    background-color: $white;
}


/*  h1 logo */

// .navbar__logo {
//   position: relative;
//   top: -0.15rem;
//   width: 220px;
//   height: 50px;
//   font-size: 200%;
//   font-weight: 500;
//   & span {
//     font-weight: 100;
//   }
// }

/* png logo */

.navbar__logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    width: 200px;
    margin-right: 1.3rem;
    @media (min-width: $sm) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    @media (min-width: $md) {
        margin-top: 0;
        margin-bottom: 0;
        top: 1px;
    }
    @media (min-width: $lg) {
        width: 210px;
    }
}

.navbar__brand {
    margin-top: 1.7rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;
    @media(min-width: $md) {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    & img {
        @media(max-width: $md) {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

.navbar__brand::after {
    content: ' ';
    position: absolute;
    width: 30%;
    margin: 0 auto;
    border-bottom: solid 1.5px $blue;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    @media(min-width: $md) {
        display: none;
    }
}

.navbar__branding {
    display: inline-block;
    font-size: 90%;
    letter-spacing: -0.3px;
    padding: 0rem 1.3rem;
    order: -1;
    margin-top: 1rem;
    display: none;
    @media (min-width: $xs) {
        display: inline-block;
        margin-top: 0;
    }
    @media (min-width: $sm) {
        display: inline-block;
        font-size: 90%;
    }
    @media (min-width: $md) {
        display: inline-block;
        font-size: 95%;
        margin-top: 0;
        justify-content: space-between;
        align-items: center;
        order: 0;
        border-left: 1px solid $white;
    }
    @media (min-width: $lg) {
        display: inline-block;
        font-size: 110%;
    }
}

.navbar__items {
    margin-top: 1rem;
    margin-bottom: 2rem;
    @media (min-width: $md) {
        flex-grow: 1;
        text-align: right;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.navbar__item {
    display: block;
    margin-bottom: 0.7rem;
    padding-bottom: 0.7rem;
    text-decoration: none;
    @media (min-width: $xs) {
        padding-bottom: 0;
        display: inline-block;
        margin-left: 1.5rem;
        margin-bottom: 0;
    }
    @media (min-width: $sm) {
        margin-left: 1.5rem;
    }
    @media (min-width: $md) {
        margin-left: 0.8rem;
    }
    @media (min-width: $md) {
        margin-left: 1.5rem;
    }
}

.navbar__link {
    font-size: 100%;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white-link;
}

.navbar__link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: rgba($white-link, 0.8);
    transition: width .3s;
}

.navbar__link:hover {
    color: rgba($orange-link, 0.8);
    text-decoration: none;
}

.navbar__link:hover::after {
    width: 100%;
    //transition: width .3s;
}

.navbar__menu-icon {
    display: block;
    width: 20px;
    height: 19px;
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    cursor: pointer;
    @media (min-width: $md) {
        display: none;
    }
}

.navbar__menu-icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: #FFF;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.navbar__menu-icon__middle {
    position: absolute;
    top: 8px;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: #FFF;
    transition: all .3s ease-out;
    transform-origin: 0 50%;
}

.navbar__menu-icon::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: #FFF;
    transform-origin: 0 100%;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: background-color .3s;
}

.navbar__menu-icon--close-x::before {
    transform: rotate(45deg) scaleX(1.25);
    background-color: $dark-grey;
}

.navbar__menu-icon--close-x .navbar__menu-icon__middle {
    opacity: 0;
    transform: scaleX(0);
    background-color: $dark-grey;
}

.navbar__menu-icon--close-x::after {
    transform: rotate(-45deg) scaleX(1.25) translateY(1px);
    background-color: $dark-grey;
}

@media(max-width: $md) {
    .navbar__link {
        color: $dark-grey;
    }
    .navbar__items {
        align-self: flex-start;
        padding: 0 1rem;
        text-align: left;
        font-size: 0.8em;
        width: 100%;
    }
    .navbar__item:not(:last-child) {
        border-bottom: 1px solid $grey;
    }
}