/* CLAIM FORM */
.simple-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;

  @media (min-width: $md) {
    margin-bottom: 4rem;
    flex-direction: row;
    align-items: unset;
    margin-bottom: 0;
  }

  &__input-wrapper {
    width: 100%;
    margin-bottom: 1rem;

    @media (min-width: $md) {
      width: 300px;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    @media (min-width: $lg) {
      width: 400px;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  &__label {
    color: $light-text;
    position: relative;
  }

  &__submit-button {
    @extend %btn;
    @extend %btn--orange;
    position: relative;
    border-radius: 3px;
    // z-index: 100;
    width: 100%;
    transition: 0.05s all linear;

    @media (min-width: $md) {
      width: auto;
    }

    &:hover .simple-form__icon-arrows {
      transform: scale(1.1);
    }

    &:disabled {
      background-color: #ddd;
      pointer-events: none;
    }

    &:disabled:hover {
      background-color: #ddd;
    }
  }

  &__icon-arrows {
    @extend %icon__arrows;
  }
}

/* SELECT DROPDOWN */
.select {
  &__wrapper {
    position: relative;
    flex-basis: 240px;
    position: relative;
    transition: 0.1s all ease-in;

    &:hover .select__arrow {
      cursor: pointer;
      background-color: $orange;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 40px;
    height: 100%;
    border-radius: 0px 3px 3px 0px;
    background-color: $dark-grey;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    pointer-events: none;

    & svg {
      width: 20px;
      height: 20px;
      fill: $white;
    }
  }

  &__element {
    display: inline-block;
    border-radius: 3px;
    width: 100%;
    font-weight: 500;
    padding: 0.5rem 0.6rem;
    background-color: $white;
    color: $dark-grey;
    border: 1px solid $dark-grey;
    -webkit-appearance: none;
    transition: 0.1s all ease-in;

    &:hover,
    &:focus {
      cursor: pointer;
      border: 1px solid $orange;
    }
  }

  &__suggestions-list {
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 100;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: auto;
    max-height: 216px;
    overflow-y: scroll;

    &:empty {
      display: none;
    }

    @media (min-width: $md) {
      &--1 {
        width: 400px;
      }

      &--2 {
        width: 535px;
      }
    }


    & li {
      position: relative;
      padding: 5px 30px;
      // border-bottom: 1px solid $light-grey;
      text-align: left;

      &:after {
        content: '';
        position: absolute;
        width: 90%;
        margin-left: 5%;
        height: 1px;
        background-color: $light-grey;
        top: 0;
        left: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: $lightest-grey
      }
    }
  }
}

/* PASSWORD FORM */
.label-newsletter-subscrip{
  margin-right: 0.5rem;
}
.password-form {
  width: 100%;

  @media (min-width: $xs) {
    width: 400px;
  }

  & label,
  & input {
    display: block;
    width: 100%;
  }

  & input {
    padding: 0.4rem;
  }

  &__submit {
    margin-top: 1.5rem;

    & input {
      padding: 0.7rem;
      @extend %btn;
      @extend %btn--blue;
    }
  }
}

/* USER DATA FORM */
.user-data-form {
  &__submit {
    flex-basis: 100%;
    text-align: center;
    margin-top: 1.5rem;

    & input {
      padding: 0.7rem;
      @extend %btn;
      @extend %btn--blue;
    }
  }
}

/* SEO FORM */
.seo-form {
  &__submit {
    flex-basis: 100%;
    text-align: center;
    margin-top: 1.5rem;

    & input {
      padding: 0.7rem;
      @extend %btn;
      @extend %btn--blue;
    }
  }
}

/* CREDIT CARD CHECKBOX BUTTONS */
.radio {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @media (min-width: $sm) {
    margin: 0.5rem 0;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 20px;

    +.radio-label {
      pointer-events: none;

      & svg {
        position: relative;
        top: 3px;
        width: 30px;
        height: 30px;
      }

      &:before {
        content: '';
        background: $light-grey;
        border-radius: 100%;
        border: 1px solid darken($light-grey, 25%);
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        top: 6px;
        margin-right: 0.2em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;

        @media(min-width: $xs) {
          width: 1.4em;
          height: 1.4em;
          margin-right: 1em;
        }
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          background-color: $black;
          box-shadow: inset 0 0 0 4px $black;
        }
      }
    }

    &:focus {
      +.radio-label {
        &:before {
          outline: none;
          border-color: $grey;
        }
      }
    }

    &:disabled {
      +.radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-grey;
          border-color: darken($light-grey, 25%);
          background: darken($light-grey, 25%);
        }
      }
    }

    +.radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }

  & span {
    display: inline-block;
    white-space: nowrap;
    margin: 0.1rem 6px;
    font-size: 0.8em;

    @media (min-width: $sm) {
      font-size: 1em;
      margin: 0;
    }

    & strong {
      display: block;
      font-size: 1em;

      @media (min-width: $sm) {
        display: inline;
      }
    }
  }

  &__action-button {
    @extend %btn;
    @extend %btn__link;
  }
}

/* LOGIN FORM */
.sign-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 10rem;

  &__wrapper {
    position: relative;
    width: 400px;
    padding: 10px 0;
  }

  &__title {
    @extend %heading--search;
    text-align: left;
    margin: 0;
  }

  &__messages {
    position: absolute;
    width: 100%;
    height: 40px;
    top: -40px;
    left: 0;
  }

  &__info,
  &__error-box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: all 0.1s ease-in;
  }

  &__info {
    border: 1px solid $green;
    border-radius: 4px;
    color: $green;
  }

  &__error-box {
    border: 1px solid red;
    color: red;
  }

  &__label {
    display: block;
    margin-top: 0.5rem;
    font-size: 16px;
    font-weight: 400;

    &.error {
      color: red;
    }
  }

  &__input {
    width: 100%;
    padding: 7px;
    font-size: 16px;
    // outline: 1px solid transparent;
    // border: 1px solid $dark-grey;
    margin-bottom: 0.2rem;

    &:focus {
      // border: 1px solid $orange;
      // outline: 1px solid $orange;
    }

    &.error {
      border: 1px solid red;
      background-color: rgba(255, 0, 0, 0.055);
    }
  }

  &__forget {
    & a {
      color: $blue;
    }
  }
// KE: begin
  &__newsletter-subscrip-submit{
    @extend %btn;
    @extend %btn--orange;
    position: relative;
    display: block;
    padding: 0.3rem 1.5rem;
    margin: 0.5rem 0rem 0rem 0rem;
    text-align: center;
    width: 100%;
    transition: 0.05s all linear;
    text-transform: uppercase;
    font-weight: bold;
  }
  // KE: end
  #newsletter-subscriptin-col{
    padding: 0;
  }

  &__submit {
    @extend %btn;
    @extend %btn--orange;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 10px;
    transition: 0.05s all linear;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1.5rem;
  }

  &__error {
    display: none;

    &.error {
      display: block;
      color: red;
      font-size: 14px;
    }
  }
}
.newsletter-subscrip-center{
  display: flex;
  justify-content: center;
  align-items: center;

   }


.payment-form {
  background: #eee;
  margin-top: 16px;
  padding: 16px;

  &__submit {
    flex-basis: 100%;
    text-align: center;
    margin-top: 1.5rem;

    & input {
      padding: 0.7rem;
      @extend %btn;
      @extend %btn--blue;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.subscription-form {
  display: flex;
  flex-direction: column;

  &__user-detail__headline {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.4em;
  }

  &__payment-detail__headline {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.4em;
    margin-top: 1em;
    display: none;

    @media (min-width: $sm) {
      display: block;
    }
  }

  & .subscriptions-plans__card-header-title {
    text-align: center;
  }

  & .btn {
    @extend %btn;
    background-color: $orange-button;
    color: $white;
    font-size: 110%;
    font-weight: 500;
    padding: 0.7rem;

    &:hover {
      background-color: lighten($dark-button, 10%);
    }
  }

  & .payment-address-elements {
    display: flex;
    flex-wrap: wrap;

    & .payment-address_postal-region {
      display: flex;
    }

    & .payment-address__element {
      width: 100%;
      padding: 0;

      @media (min-width: $sm) {
        width: 50%;
        padding-right: 1em;
      }

      & label {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        font-size: 0.8em;
      }

      & input {
        width: 100%;
      }

      & .payment-address__street-name {
        width: 80%;
        padding-right: 0.5em;
      }

      & .payment-address__house-number {
        width: 18%;
      }

      & .payment-address__half {
        flex: 1;

        &-first {
          padding-right: 0.5em;
        }
      }
    }
  }

  & .payment-action {
    order: 3;
    text-align: right;
    margin-top: 3rem;
  }

  & .payment-content {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    & .payment-side {
      order: 1;

      @media (min-width: $sm) {
        order: 2;
        flex: 1;
      }

      & #payment-information {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
        padding: 0 0 15px 0;
        margin-bottom: 0;

        @media (min-width: $sm) {
          margin-bottom: initial;
          position: initial;
          bottom: initial;
          left: initial;
          padding: initial;
        }

        &__content {
          display: none;

          @media (min-width: $sm) {
            display: block;
          }
        }

        & .payment-information__show {
          padding: 0.7rem;
          @extend %btn;
          @extend %btn--blue;
          width: 100%;

          @media (min-width: $sm) {
            display: none;
          }
        }
      }
    }

    &__section {
      order: 2;

      @media (min-width: $sm) {
        flex: 2;
        order: 1;
        padding-right: 2em;
      }

      & #card-form {
        padding-bottom: 2em;
      }
    }

    &__check-address {
      margin: 1.5em 0;

      & h3 {
        font-size: 1.2em;
      }
    }
  }

  & .payment-information__table {
    width: 100%;

    & th {
      color: white;
      background-color: $blue;
      text-align: left;
      padding: 0.4em;
    }

    &__last-row {
      text-align: right;
    }

    & td {
      padding: 0.4em;
    }

    & .price {
      text-transform: uppercase;
      text-align: right;
      white-space: nowrap;
    }

    & .big {
      font-weight: bold;
      border-top: solid 2px black;
    }
  }
}

/* STRIPE ELEMENTS */

.StripeElement {
    background-color: $light-grey;
    border-radius: 4px;
    padding: 7px;
}

.StripeElement--focus {
    border-right: 3px solid $orange;
}

/* ERRORS */
.field-error {
  color: red;
  font-size: 14px;
}