.error-jumbotron {
  @extend %section;
  background-color: $grey-box;
  padding: 3rem 0;
  @media (min-width: $md) {
    padding: 5rem 0;
  }

  &__title {
    @extend %heading--hero;
    @extend %heading--orange;
  }

  &__description {
    @extend %text-section;
    width: 90%;
    margin: 0 auto;
    @media (min-width: $md) {
      width: auto;
      margin-top: -1.5rem;
      margin-bottom: 1rem;
    }
    @media (min-width: $lg) {
      margin-top: -2.5rem;
      margin-bottom: 1rem;
    }
  }

  &__actions {
    width: 90%;
    margin: 2rem auto 0;
    text-align: center;
    @media (min-width: $xxs) {
      width: 80%;
    }
    @media (min-width: $xs) {
      width: 70%;
      padding: 2rem;
    }
    @media (min-width: $sm) {
      width: auto;
    }
  }

  &__button {
    display: block;
    margin-bottom: 1rem;
    @extend %btn;
    @extend %btn--dark-grey;
    @media (min-width: $sm) {
      display: inline-block;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    @media (min-width: $md) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}