/* Config */
@import "config/_variables.scss";
@import "config/_reset.scss";
@import "config/_normalize.scss";

/* Grid */
@import "config/_bootstrap-grid.scss";

/* Placeholders */
@import "placeholders/_headings.scss";
@import "placeholders/_text.scss";
@import "placeholders/_buttons.scss";
@import "placeholders/_sections.scss";
@import "placeholders/_card.scss";
@import "placeholders/_icons.scss";
@import "placeholders/_accordion.scss";

/* Components */
@import "components/_forms.scss";
@import "components/_tabs.scss";
@import "components/_ct.scss";
@import "components/_slick.scss";
@import "components/_modal.scss";
@import "components/_social-login.scss";

/* Structure */
@import "structure/_hero.scss";
@import "structure/_navbar.scss";
@import "structure/_breadcrumb.scss";
@import "structure/_footer.scss";
@import "structure/_cookie.scss";
@import "structure/_offers.scss";
@import "structure/_service-provider-card.scss";

/* Pages */
@import "pages/_welcome.scss";
@import "pages/_search.scss";
@import "pages/_service-provider-details.scss";
@import "pages/_user.scss";
@import "pages/_static.scss";
@import "pages/_error.scss";
@import "pages/_subscriptions.scss";
@import "pages/_overview.scss";
@import "pages/_login.scss";
@import "pages/_kontakt.scss";

/* Shared Styles */
@import "shared/_shared.scss";

/* selected Bootstrap components */
/*@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/_close.scss";
@import "../node_modules/bootstrap/scss/_forms.scss";
@import "../node_modules/bootstrap/scss/_modal.scss";*/
