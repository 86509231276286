body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  color: $dark-text;
  line-height: 1.2;

  @media (min-width: $xxs) {
    line-height: 1.5;
    font-size: 16px;
  }
}
// KE: begin
form div.newsletter-subscrip-group{
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
// KE: end

* {
  box-sizing: border-box;
}

// Screen reader text
.srt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

a {
  color: inherit;
}

a:hover {
  color: $orange-link;
  text-decoration: underline;
}

.btn__small {
  @extend %btn__small;
  background-color: $dark-button;
  color: $white;
}

/* SCROLL TO TOP */
.scroll-to-top {
  width: 2em;
  height: 2em;
  position: fixed;
  bottom: 20px;
  left: -100%;
  color: $orange;
  opacity: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  &--shown {
    left: 5px;
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.lds-dual-ring {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1.2em;
  height: 1.2em;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid $orange;
  border-color: $orange transparent $orange transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div.stars-rating {
  display: flex;
  width: 175px;
}

div.stars-rating>span {
  display: block;
  height: 25px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526' width='24' height='24' viewBox='0 0 24 24'><path d='M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524-4.721 2.525.942-5.27-3.861-3.71 5.305-.733 2.335-4.817zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z'/></svg>");
}

/* Persistent state */
div.stars-rating[data-rating-value]>span {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526' width='24' height='24' viewBox='0 0 24 24'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/></svg>");
  /* full star */
}

div.stars-rating>span.active~span {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526'  width='24' height='24' viewBox='0 0 24 24'><path d='M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524-4.721 2.525.942-5.27-3.861-3.71 5.305-.733 2.335-4.817zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z'/></svg>");
  /* empty star */
}

div.stars-rating>span.active.half-star {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526'  width='24' height='24' viewBox='0 0 24 24'><path d='M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524v-12.005zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z'/></svg>") !important;
  /* half star */
}

div.stars-rating>span.active {
  animation: scale 0.5s;
}

/* Hover state */
div.stars-rating[class]:hover>.clickable {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526' width='24' height='24' viewBox='0 0 24 24'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/></svg>");
  /* gold star */
  cursor: pointer;
}

div.stars-rating[class]>.clickable:hover~.clickable {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fa8526' width='24' height='24' viewBox='0 0 24 24'><path d='M12 5.173l2.335 4.817 5.305.732-3.861 3.71.942 5.27-4.721-2.524-4.721 2.525.942-5.27-3.861-3.71 5.305-.733 2.335-4.817zm0-4.586l-3.668 7.568-8.332 1.151 6.064 5.828-1.48 8.279 7.416-3.967 7.416 3.966-1.48-8.279 6.064-5.827-8.332-1.15-3.668-7.569z'/></svg>");
  /* gray star */
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

/* INPUTS */
.mb-input {
  padding: 0.4em;

  &:focus {
    // outline: 2px solid $orange;
  }
}

.mb-accept-checkbox {
  margin: 16px 0;
}

.mb-btn {
  padding: 0.7rem;
  @extend %btn;
  @extend %btn--blue;

  &:hover {
    background-color: lighten($dark-button, 10%);
  }
}

/* GENERAL */

input,
textarea,
select {
  border-radius: 4px;
  padding: 0.4rem;
  outline: none;
  border: none;
  background-color: $light-grey;
}
// KE: begin
input.newsletter-subscrip-input {
  margin:1px;
  padding:0.2rem 0.4rem;

  background-color: $white;
  width:100%;
}



// KE: end

input:focus,
textarea:focus,
select:focus {
  border-right: solid 3px $orange;
}

input:hover,
textarea:hover,
select:hover {
  background-color: $lightest-grey;
}

.w-full {
  width: 100%;
}

.mobile {
  display: block;
}

.desktop {
  display: none;
}

.tooltip {
  position: relative;
  cursor: default;
  border: none;
}

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 220px;
  background-color: #00000093;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #00000093 transparent transparent transparent;
}

.tooltip:hover {
  background: transparent;
}

.tooltip:focus {
  outline: none;
}

.tooltip:hover .tooltiptext,
.tooltip:active .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.google-ad-wrapper {
    display: block;
    width: 400px;
}

.desktop_rating_ad {
  width: 300px;
  height: 250px;
}

.mobile_rating_ad {
  width: 300px;
  height: 250px;
}

@media(min-width: 500px) {
  .desktop_rating_ad { width: 468px; height: 60px; }
  .mobile_rating_ad { width: 468px; height: 60px; }
}

.google-demo-ad {
    color: white;
    font-size: 3rem;
    width: 100%;
    height: 220px;
    text-align: center;
    vertical-align: center;
    background-color: #4d79a2;
    border-radius: 5px;
}

@media(min-width: $md) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}