/* Login Section Divider  */

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin: 30px 0;
}

.strike>span {
    position: relative;
    display: inline-block;
}

.strike>span:before,
.strike>span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #32536e;
}

.strike>span:before {
    right: 100%;
    margin-right: 15px;
}

.strike>span:after {
    left: 100%;
    margin-left: 15px;
}


/* Wrapper containing all login action buttons */

.social-login-buttons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
}


/* Social Login Apple Button Styling */

.apple-login-button {
    cursor: pointer;
}


/* Social Login Google Button Styling */

.google-login-btn {
    width: 220px;
    height: 42px;
    font-weight: 100;
    background-color: #fff;
    border: 1px solid #000000;
    box-shadow: 0 1px 1px -1px #000;
    border-radius: 5px;
    cursor: pointer;
}

.google-login-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: #fff;
    overflow: hidden;
}

.google-login-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 16px;
    height: 16px;
    overflow: hidden;
}

.google-login-btn-text {
    float: left;
    margin: 10px 0 0 40px;
    color: #000;
    font-size: 17px;
    font-family: Roboto, sans-serif;
}

.social-login-choose-wrapper {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.social-login-choose-field {
    padding: 25px 15px;
    max-width: 250px;
    border: 1px solid #a9a9a9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}