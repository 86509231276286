            /* Hero Section */
            
            .section--hero {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                text-align: center;
                max-width: 100%;
                background: white;
                @media (min-width: $md) {
                    // overflow: hidden;
                }
            }
            /* Slider */
            
            .hero {
                &__slider {
                    width: 100%;
                    z-index: -20;
                    background: white;
                    min-height: 20vh;
                    overflow: hidden;
                    @media (min-width: $md) {
                        min-height: 32rem;
                        & .hero__title {
                            top: 100px;
                        }
                    }
                    &-image {
                        overflow: hidden;
                        width: 100%;
                        position: relative;
                        // min-height: 20vh;
                        // @media (min-width: $sm) {
                        //   min-height: 20rem;
                        // }
                        // @media (min-width: $md) {
                        //   min-height: 26rem;
                        // }
                        // @media (min-width: $md) {
                        //   min-height: 32rem;
                        // }
                        @media (min-width: $lg) {
                            max-height: 620px;
                            overflow: hidden;
                        }
                        & img {
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        & .hero__title {
                            position: relative;
                            text-align: center;
                            margin-left: auto;
                            margin-right: auto;
                            font-size: 1.6em;
                            z-index: 0;
                            @media (min-width: $xs) {
                                font-size: 220%;
                            }
                            @media (min-width: $sm) {
                                margin-bottom: 20%;
                            }
                            @media (min-width: $md) {
                                // position: absolute;
                                // left: 0;
                                // right: 0;
                                // top: 35%;
                                margin-bottom: 0%;
                                font-size: 300%;
                            }
                        }
                    }
                }
            }
            
            .hero__content {
                position: static;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $grey;
                width: 100%;
                padding-top: 1em;
                @media (min-width: $sm) {
                    position: absolute;
                    left: 0px;
                    bottom: 0rem;
                    background-color: transparent;
                    padding-top: 0;
                }
                @media (min-width: $md) {}
                @media (min-width: $lg) {
                    // bottom: 28rem;
                }
            }
            
            .hero__title {
                @extend %heading--hero;
                position: relative;
                z-index: 100;
                width: 95%;
                // margin-top: 1rem;
                // margin-bottom: 1rem;
                text-shadow: 0px 0px 20px black;
                @media (min-width: $xxs) {
                    width: 400px;
                    // margin-top: 2rem;
                    // margin-bottom: 1.5rem;
                }
                @media (min-width: $xs) {
                    width: 500px;
                    // margin-top: 3rem;
                    // margin-bottom: 3rem;
                }
                @media (min-width: $sm) {
                    display: block;
                    width: 600px;
                    // margin-top: 2rem;
                    // margin-bottom: 2rem;
                    text-shadow: none;
                }
                @media (min-width: $md) {
                    width: 600px;
                    // margin-top: 4rem;
                    // margin-bottom: 5rem;
                }
                @media (min-width: $lg) {
                    width: 700px;
                    // margin-top: 0rem;
                    // margin-bottom: 4rem;
                }
                @media (min-width: $xl) {}
                &-wrapper {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
            
            .hero__title--orange {
                @extend %heading--orange;
            }
            
            .hero__form {
                width: 90%;
                padding-bottom: 3rem;
                @media (min-width: $xxs) {
                    width: 80%;
                }
                @media (min-width: $xs) {
                    width: 70%;
                }
                @media (min-width: $sm) {
                    width: 60%;
                    padding-bottom: 1rem;
                }
                @media (min-width: $md) {
                    width: 900px;
                }
                @media (min-width: $lg) {
                    width: 1000px;
                    padding-bottom: 5rem;
                }
            }
            /* Sections */
            
            .section {
                @extend %section;
            }
            
            .section__title {
                @extend %heading--section;
            }
            
            .section__title--orange {
                @extend %heading--orange;
            }
            
            .section__description {
                @extend %text-section;
            }
            
            .bg-section {
                background-color: white;
                margin: 0;
                padding: 24px 0;
                margin-bottom: -1px;
            }
            /* Mobile Dienstleistungen Section */
            
            .section--mobile {}
            
            .mobile__list {
                margin-top: 2rem;
                justify-content: center;
                @extend .row;
            }
            
            .mobile__item {
                @extend .col-md-4;
                margin-bottom: 2.5rem;
            }
            
            .mobile__item:last-child {
                margin-bottom: 0;
            }
            
            .mobile__item-card {
                @extend %card;
            }
            
            .mobile__item-img {
                width: 100%;
            }
            
            .mobile__item-title {
                @extend %heading--card-upper;
                margin: 1rem 0 0.7rem;
                text-align: center;
            }
            
            .mobile__item-text {
                @extend %card__text;
                margin-bottom: 0.7rem;
                text-align: center;
            }
            /* Hero 2 Section */
            
            .section--hero-2 {
                // height: 100%;
                // background-image: url('/images/page/large/Mobiler_Personaltrainer.jpg');
                // background-repeat: no-repeat;
                // background-size: cover;
                // -webkit-background-size: cover;
                // -moz-background-size: cover;
                // -o-background-size: cover;
                // background-size: cover;
                // background-position: center;
                // background-attachment: initial;
                background-color: rgba(18, 50, 82, 0.5);
                margin-bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (min-width: $sm) {
                    background-attachment: initial;
                    background-attachment: fixed;
                }
                & .section__title {
                    margin: 5rem 0.5rem;
                    color: $white;
                    @media (min-width: $lg) {
                        margin: 5em 0.5rem;
                    }
                }
            }
            
            .section--hero-2::after {
                content: "";
                position: fixed;
                display: block;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -100;
                background-image: url("/images/page/large/Mobiler_Personaltrainer.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: center;
                background-attachment: initial;
            }
            
            .hero__title-2 {
                @extend %heading--hero;
            }
            /* Dienstleistungen Section */
            
            .providers__list {
                margin-top: 2rem;
                @extend .row;
            }
            
            .providers__item {
                @extend .col-md-4;
                margin-bottom: 1rem;
                @media (min-width: $xs) {
                    margin-bottom: 2rem;
                }
                @media (min-width: $sm) {
                    margin-bottom: 1rem;
                    padding: 0 5px;
                }
                & a:hover {
                    text-decoration: none;
                }
            }
            
            .providers__item h3 {
                @media (min-width: $sm) {
                    height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            
            .providers__item-card {
                @extend %card,
                %shadow;
                @extend %card--grey;
                border-radius: 10px;
                height: 100%;
            }
            
            .providers__item-header {
                display: flex;
                flex-flow: row nowrap;
                flex-direction: column;
                @media (min-width: $xxs) {
                    flex-direction: row;
                }
                @media (min-width: $sm) {
                    flex-direction: column;
                }
                @media (min-width: $md) {
                    flex-direction: row;
                }
            }
            
            .providers__item-avatar {
                & img {
                    height: 120px;
                    width: 120px;
                    object-fit: cover;
                    border-radius: 10px 0 0;
                    @media (min-width: $xxs) {
                        max-height: 120px;
                    }
                    @media (min-width: $sm) {
                        max-height: 100px;
                        height: 120px;
                        width: 120px;
                    }
                    @media (min-width: $md) {
                        max-height: 120px;
                        margin-right: 0.5rem;
                    }
                }
            }
            
            .providers__item-who {
                display: flex;
                flex-direction: column;
                // flex-basis: 150px;
                margin-left: 1rem;
                @media (min-width: $md) {
                    margin-left: 0rem;
                }
            }
            
            a:hover .providers__item-name {
                color: $orange-text !important;
            }
            
            .providers__item-name {
                @extend %heading--card;
                margin: 0.5rem 0 0.7rem;
                font-size: 100%;
                word-break: break-word;
            }
            
            .providers__item-service {
                color: $black-text;
                flex-grow: 1;
                @media (min-width: $sm) {
                    font-size: 80%;
                }
                @media (min-width: $md) {
                    font-size: 90%;
                }
            }
            
            .providers__item-title {
                @extend %heading--card-upper;
            }
            
            .providers__item-body {
                padding: 0.3rem 1rem 1rem;
                min-height: 6em;
            }
            
            a:hover .providers__item-quote {
                color: rgb(103, 103, 103) !important;
                text-decoration: none !important;
            }
            
            a:hover .providers__item-street {
                color: rgb(103, 103, 103) !important;
                text-decoration: none !important;
            }
            
            .providers__item-distance {
                color: $orange;
                font-size: 90%;
            }
            
            .providers__item-marker {
                width: 12px;
                height: 12px;
                fill: $orange;
            }
            
            .providers__item-address {
                margin: 0.3rem 0 0.3rem;
            }
            
            .providers__item-street,
            .providers__item-city {
                display: block;
                line-height: 1.4em;
                @media (min-width: $sm) {
                    font-size: 80%;
                }
                @media (min-width: $md) {
                    font-size: 90%;
                }
            }
            
            .providers__item-quote {
                font-style: italic;
                word-break: break-word;
                @media (min-width: $sm) {
                    font-size: 80%;
                }
                @media (min-width: $md) {
                    font-size: 90%;
                }
            }
            /* Testimonials Section */
            
            // .section--testimonials,
            .testimonials__list {
                margin-top: 2rem;
                @extend .row;
            }
            
            .testimonials__item {
                @extend .col-md-4;
                text-align: center;
                margin-bottom: 1rem;
                @media (min-width: $xs) {
                    margin-bottom: 2rem;
                }
                @media (min-width: $sm) {
                    margin-bottom: 0;
                }
            }
            
            .testimonials__item-avatar {
                width: 70%;
                height: 245px;
                object-fit: contain;
                padding-bottom: 1rem;
                @media (min-width: $xxs) {
                    width: 50%;
                    padding: 0 1rem 1rem;
                }
                @media (min-width: $xs) {
                    width: 50%;
                    padding-bottom: 1rem;
                }
                @media (min-width: $sm) {
                    width: 90%;
                    padding: 1rem 2rem;
                }
                @media (min-width: $md) {
                    width: 70%;
                }
            }
            
            .testimonials__item-name {
                @extend %heading--card;
                margin: 0.3rem 0 1rem;
            }
            
            .testimonials__item-quote {
                font-style: italic;
                @media (min-width: $sm) {
                    font-size: 80%;
                }
                @media (min-width: $md) {
                    font-size: 100%;
                }
            }
            /* News section */
            
            .news__wrapper {
                @extend %card--grey;
                margin-top: 2rem;
                overflow: hidden;
                @media (min-width: $lg) {
                    display: flex;
                    flex-direction: row;
                }
            }
            
            .news__content {
                display: block;
                padding: 0.5rem 1.5rem;
                @media (min-width: $sm) {
                    padding: 0.5rem 2.5rem;
                }
                @media (min-width: $lg) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0rem 2rem;
                }
            }
            
            .news__photo {
                display: block;
                width: 100%;
                @media (min-width: $sm) {
                    width: auto;
                }
                @media (min-width: $md) {
                    width: 100%;
                }
                @media (min-width: $lg) {
                    width: auto;
                    min-height: 250px;
                }
            }
            
            .news__link {
                display: block;
                @media (min-width: $sm) {
                    margin-right: 1rem;
                    float: left;
                }
                @media (min-width: $md) {
                    margin-right: 2rem;
                }
                @media (min-width: $lg) {
                    margin-right: 0em;
                    float: none;
                }
            }
            
            .news__title {
                @extend %heading--card;
                margin: 1rem 0;
            }
            
            .news__text {
                @extend %card__text;
            }
            
            .news__more {
                width: 35px;
                height: 35px;
                fill: $orange;
                margin-top: 0.2rem;
                transition: 0.05s all linear;
                @media (min-width: $md) {
                    margin-top: 1rem;
                }
                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }