/*
* CONTENT TOOLS
*/ 

.ct {
  &-ignition,
  &-toolbox {
    z-index: 11000 !important;
  }
}

.ce-element {
  &--over {
    background-color: rgba($orange, 0.3) !important;
  }
}

/* TEXT STYLE */
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
i {
  font-style: italic !important;
}

