/* SERVICE PROVIDERS NAVIGATION */

.service-provider__navigation {
    display: none;
}


/* SERVICE PROVIDERS DETAILS */

.service-provider__details {
    @extend %section--narrow,
    %shadow,
    %card;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    @media (min-width: $xs) {
        text-align: left;
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media (min-width: $sm) {
        justify-content: space-between;
    }
}

.provider__checkmarks {
    @media (min-width: $sm) {
        display: inline;
    }
}

.provider__avatar {
    & img {
        display: block;
        height: 200px;
        width: 200px;
        margin: 0 auto 1rem;
        object-fit: cover;
        object-position: center;
        @media (min-width: $xs) {
            height: 220px;
            width: 220px;
            margin: 0 0 0;
            margin-right: 1rem;
        }
        @media (min-width: $sm) {
            height: 210px;
            width: 210px;
            margin-right: 1rem;
        }
        @media (min-width: $md) {
            height: 250px;
            width: 250px;
            margin-right: 2rem;
        }
    }
}

.provider__badge {
    text-align: center;
    font-weight: 600;
    color: $dark-grey;
    font-size: 0.8rem;
    display: inline;
    border: 2px solid $dark-grey;
    padding: 2px 8px;
    border-radius: 5px;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0 8px;
    display: inline-block;
    @media(min-width: $sm) {
        margin: 0 8px 0 0;
    }
    & svg {
        width: 15px;
        height: 15px;
        margin-right: 8px;
        margin-bottom: -3px;
    }
}

.provider__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(min-width: $md) {
        margin-left: 4em;
    }
}

.provider__content-section {
    @extend %shadow;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem 0;
}

.provider__content-section__header {
    margin: -1rem -1rem 1rem;
    border-radius: 10px 10px 0 0;
    color: white;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 0.8em 1em;
    font-weight: 600;
}

.provider__name {
    @extend %heading--card;
    @extend %heading--card--lg;
    line-height: 1;
    margin: 0;
    & small {
        font-size: 70%;
        letter-spacing: 0;
        color: $black;
        @media (min-width: $md) {
            font-size: 58%;
        }
    }
}

.provider__icon-social {
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-right: 12px;
    margin-bottom: 16px;
    fill: $orange-text;
    color: $orange-text;
    & path {
        fill: $orange-text;
        color: $orange-text;
    }
}

.provider__contact {
    margin-top: 1rem;
    margin-bottom: 0;
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    @media (min-width: $md) {
        margin-top: 2rem;
        flex-flow: row wrap;
    }
}

.provider__contact-list {
    text-align: left;
    margin-bottom: -0.5em;
}

.provider__contact-list a,
.provider__contact-list p,
.provider__contact-list address,
.provider__contact-list span {
    font-size: 0.9rem;
}

.provider__contact-list>div {
    margin: 0.5em 0;
}

.provider__icon-info {
    width: 20px;
    height: 15px;
    margin-right: 1.5rem;
    fill: $orange-text;
    color: $orange-text;
    & path {
        fill: $orange-text;
        color: $orange-text;
    }
}

.provider__phone,
.provider__email,
.provider__address {
    @extend %text--details;
}

.provider__address {
    display: inline;
    @media (min-width: $md) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.provider__share {
    @extend %btn;
    @extend %btn__share;
    width: 175px;
    white-space: nowrap;
    margin: 0.5rem auto 0.8rem;
    @media (min-width: $md) {
        margin: 0 0 0 1em;
    }
    &-wrapper {
        display: flex;
        flex-flow: column;
        @media (min-width: $md) {
            flex-flow: row wrap;
            float: right;
        }
    }
}

.provider__rate {
    @extend %btn;
    @extend %btn__share;
    margin-bottom: 16px;
    text-decoration: underline;
    font-size: 100%;
    @media(min-width: $md) {
        text-decoration: none;
        margin-bottom: 0px;
    }
}

.rating__icon-arrows {
    width: 2em;
    height: 1em;
    padding-bottom: 0.2em;
    transform: rotate(180deg);
    fill: $orange;
}

.rating__back {
    margin-bottom: 16px;
    color: $orange;
}

.rating__details-box {
    color: $dark-grey;
    margin-top: 64px;
    font-size: 1.2em;
    font-weight: 400;
    border-top: solid 2px $dark-grey;
    padding-top: 16px;
}

.rating-card {
    text-align: left;
    margin: 16px 0;
    background-color: $light-grey;
    border-radius: 10px;
    padding: 16px 2rem;
    position: relative;
    &__header {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: flex-start;
        @media(min-width: $md) {
            flex-direction: row;
            align-items: center;
        }
    }
    &__small {
        @extend %shadow;
        height: calc(100% - 1.5rem);
        overflow: hidden;
    }
    &__title {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 8px;
    }
    &__content {
        margin-top: 8px;
    }
    &__reply {
        padding: 8px;
        margin-top: 8px;
        background: #ddd;
        & h4 {
            font-weight: 700;
        }
    }
    & button {
        margin-top: 8px;
    }
    &__provider {
        color: $white;
        background: $blue;
        padding: 0 1rem;
        display: inline-block;
        margin-left: 1rem;
        border-radius: 10px 10px 0 0;
    }
    &__overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background: #ffffffd6;
        text-decoration: none;
        transition: opacity 0.3s;
        padding: 1rem;
        color: $orange;
    }
    &__overlay:hover {
        opacity: 1;
    }
}

@media(min-width: $md) {
    .providers__item .rating-card__header {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

a.rating-card__provider {
    text-decoration: underline;
}

a.rating-card__provider:hover {
    background: $white;
}

.rating-title {
    font-size: 1.2em;
    text-align: left;
}

.rating-full-container {
    text-align: center;
    & .rating-big {
        font-size: 3em;
    }
    & .rating-summary-display {
        margin-bottom: 32px;
        & .stars-rating {
            margin: 0 auto;
        }
    }
}

.rating__more {
    text-decoration: underline;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.rating-details-container {
    & dl {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 16px;
        margin-top: 8px;
    }
    & dt {
        width: 40%;
        text-align: left;
        padding-top: 8px;
        font-weight: 700;
    }
    & dd {
        width: 60%;
    }
}

@media(max-width: $xs) {
    .rating-details-container {
        & dl {
            display: block;
        }
        & dt {
            width: 100%;
        }
        & dd {
            width: 100%;
            margin-bottom: 16px;
        }
    }
}

.show-rating-details {
    border: none;
    color: $blue;
    display: block;
    cursor: pointer;
    padding: 0;
    text-align: right;
    width: 100%;
    font-size: 0.8em;
}

.percent-inner {
    background: $blue;
    height: 100%;
}

.percent-outer {
    background: $grey;
    width: 100%;
    height: 100%;
}

.percent-bar {
    width: 130px;
    min-width: 130px;
    margin: 0 8px 0 16px;
    height: 16px;
}

@media(min-width: $xxs) {
    .percent-bar {
        width: 160px;
        min-width: 160px;
    }
}

.percent-bar-container {
    display: flex;
    align-items: center;
}

.provider__stars-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    &>span {
        margin-right: 16px;
    }
}

.rating-rules {
    padding: 1em;
    border-radius: 10px;
    margin: 1em 0;
    & ul {
        list-style: disc;
        padding-left: 1em;
    }
    & p {
        font-weight: 600;
        line-height: 1em;
        margin-bottom: 8px;
    }
}

.provider__icon-share {
    position: relative;
    top: 2px;
    width: 15px;
    height: 15px;
}

.provider__more {
    // flex-basis: 100%;
    margin: 0;
    @media (min-width: $xxs) {
        width: 70%;
    }
    @media (min-width: $xs) {
        width: 50%;
    }
    @media (min-width: $sm) {
        width: auto;
    }
    @media (min-width: $xs) {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    @media (min-width: $sm) {
        flex-basis: auto;
        margin-top: 0;
        display: flex;
        justify-content: space-between;
    }
}

.provider__report {
    @extend %btn;
    @extend %btn__report;
    margin: 0 auto;
    margin-bottom: 16px;
    background-color: $strong-grey;
}

.provider__show-btn {
    background-color: $white;
    color: $orange;
    text-decoration: underline;
    border: none;
    cursor: pointer;
}

.provider__bold {
    font-weight: 600;
}

.provider__show-text {}

.provider__icon-arrows {
    @extend %icon__arrows;
}

.provider__map {
    width: 100%;
    margin: 1rem auto 0;
    @media (min-width: $sm) {
        margin: 0;
    }
}


/* PROVIDER TABS */

.service-provider__tabs {
    @extend %section;
    border-bottom: 1px solid $black;
    margin-bottom: 3rem;
}


/* SIMILAR PROVIDERS */

.similar__providers {
    @extend %section;
    &-header {
        border-bottom: 1px solid $black;
        margin-bottom: 2rem;
        & h2 {
            margin: 0.5rem 0;
        }
    }
}


/* RATING */

.rating__side {
    border-left: solid 2px #eee;
}