/**************/
/* TABS
/**************/

/* TAB LINKS */
.tab {
  &--account {

    display: none;
    overflow: visible;

    @media (min-width: $sm) {
      display: block;
    }

    & .js__unsaved-warning {
      position: absolute;
      top: -28px;
      font-size: 10px;
      color: $blue;
    }
  }

  position: relative;
  overflow: hidden;

  &__border {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 0;
    height: 4px;
    border-top: 3px solid $light-grey;
  }

  &__link {
    z-index: 1;
    position: relative;
    border: none;
    text-transform: uppercase;
    font-size: 0.6em;
    font-weight: 700;
    border-radius: 10px 10px 0 0;
    background-color: $white;
    border-bottom: 3px solid $light-grey;
    color: $black-text;
    cursor: pointer;
    transition: box-shadow 0.5s;

    @media (min-width: $xs) {
      padding: 8px 12px;
      font-size: 0.7em;
    }

    @media (min-width: $md) {
      padding: 12px 16px;
      font-size: 0.8em;
    }
  }

  &__link:hover {
    border-bottom: 3px solid $dark-grey;
  }

  &__link.active {
    border-bottom: 3px solid $dark-grey;
  }

  &__link:focus {
    outline: none;
  }

  &__select--mobile {
    @media (min-width: $sm) {
      display: none;
    }
  }
}

.tabs-details {
  margin-top: 2em;
}

.tab-flex {
  display: flex;
  flex-flow: column-reverse;

  @media(min-width: $md) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.tab-wrapper {
  display: flex;
  flex-wrap: wrap;
}


/* TAB CONTENT SHARED */
.tab__content {
  display: none;
  width: 100%;
  padding: 1rem 0 1.5rem;
  border-top: none;
  max-height: none;
  overflow-y: visible;

  @media (min-width: $md) {
    overflow-y: visible;
    min-height: 400px;
    padding: 2rem 0;
  }

  &.active {
    display: block;
  }
}

.tab__main-title {
  @extend %heading--card;
  margin-bottom: 1rem;
}

.tab__subtitle {
  @extend %heading--tabs;

  &-icon {
    position: relative;
    top: -5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.tab__text {
  @extend %text--details;
}

.tab__label {
  @extend %heading--labels;
  display: block;
  margin-top: 1rem;
}

.tab__table-card {
  margin-right: 8px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: #ccc 3px 3px 9px 0px;
  margin-bottom: 1.5rem;

  & h3 {
    color: white;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 0.8em 1em;
    font-weight: 600;
  }
}

.tab__table {
  border-collapse: collapse;
  margin: 2em 1em;
  width: calc(100% - 2em);

  td,
  th {
    border: none;
    text-align: left;
    font-size: 90%;
    padding: 3px 2px;

    &:not(:last-child) {
      padding-right: 12px;
    }

    @media (min-width: $xs) {
      padding: 8px 6px;

      &:not(:last-child) {
        padding-right: 12px;
      }
    }

    @media (min-width: $md) {
      font-size: 95%;
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid $dark-grey;
  }

  thead tr {
    border-bottom: 1px solid $dark-grey;
  }

  th {
    color: $dark-text;
    font-weight: 700;
  }

  td {
    color: $dark-text;
  }

  td[colspan="2"] {
    text-align: center;
    font-weight: bold;
  }

  svg {
    width: 15px;
    height: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

/* TAB OVERVIEW */
.tab__overview {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-details {
    margin-right: 0;
    flex-grow: 2;
    margin-bottom: 2em;

    @media (min-width: $md) {
      margin-right: 2rem;
    }
  }

  &-gallery {
    display: flex;
    justify-content: left;
    flex-flow: row wrap;

    & .imageDelete {
      cursor: pointer;
    }

    & li {
      width: 33.3333%;
      padding: 33.3333% 0 0;
      position: relative;

      @media (min-width: $xs) {
        width: 20%;
        padding: 20% 0 0;
      }

      @media (min-width: $md) {
        width: 12.5%;
        padding: 12.5% 0 0;
      }

      padding-right: .5rem;
      padding-bottom: .5rem;
    }

    & a {
      position: absolute;
      top: 0;
      width: 90%;
      height: 90%;
    }

    & img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &-range {
    word-break: break-word;

    & ul {
      font-size: 85%;

      @media (min-width: $md) {
        font-size: 95%;
      }

    }

    & li:before {
      content: '-';
      margin-right: 0.5rem;
    }
  }

  &-info {
    flex-basis: 0px;
    flex-shrink: 0;

    @media(min-width: $sm) {
      flex-basis: 350px;
    }
  }

  &-addprice {
    padding-top: 5px;
    margin-bottom: 2em;
    text-align: center;
  }

  &-short-input {
    width: 40px !important;

    @media(min-width: $xs) {
      width: 60px !important;
    }
  }


  &-long-input {
    width: 100%;
  }

  &-pricing {
    @extend .tab__table-card;

    & table {
      @extend .tab__table;
    }

    & h3 {
      background-color: $orange;
    }

    &-table--last-row {
      width: 90px;
    }
  }

  &-regular-hours {
    @extend .tab__table-card;

    & table {
      @extend .tab__table;
    }

    & h3 {
      background-color: $blue;
    }

    &-table--last-row {
      width: 110px;
    }

    &-table--first-row {
      width: 120px;
    }

  }

  /* paragraph solution */
  &-lang {
    @extend .tab__table-card;

    >div {
      font-size: 95%;
    }

    & h3 {
      background-color: $strong-grey;
    }

    &-text {
      margin: 2em 1em;
      color: $dark-grey;
    }
  }

}

/* TAB SERVICES */

.tab__service {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-content {
    margin-bottom: 1.5em;
    flex-grow: 1;
  }

  &-wrapper {
    display: flex;
    justify-content: left;
    flex-direction: row;
  }

  &-menu {
    flex-basis: 220px;
    max-width: 220px;
    margin-top: 8px;
    margin-right: 1em;

    &-selected {
      border-radius: 10px;
      background-color: $light-grey;
    }

    &-item {
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-selected {
    @extend %card,
    %shadow;
    display: flex;
    flex-direction: column;
    background-color: $grey;
    margin-left: 16px;
    padding: 16px;

    &-line {
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &-list {
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;

    @media (min-width: $md) {
      margin-top: 3.8rem;
    }

    & ul {
      border: 1px solid $dark-grey;
      display: inline-block;
      width: 80%;
    }

    & div {
      width: 80%;
      padding: 0.4rem 0.9rem;
    }

    & li {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem 0.9rem;
      font-size: 90%;
      font-weight: 400;
      color: $black-text;

      &:hover {
        cursor: pointer;
        background-color: lighten($blue, 40%);
      }

      @media (min-width: $md) {
        font-size: 100%;
      }
    }

    & .active {
      background-color: $light-grey;
    }
  }

  &-desc {
    flex-basis: 65%;
  }

  &-desc-item {
    display: none;
    word-break: break-word;

    &.active {
      display: block;
    }

    & h3 {
      @extend %heading--tabs;

      &-icon {
        position: relative;
        top: -5px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    & p {
      @extend %text--details;
    }

    & table {
      @extend .tab__table;
    }

    & ol,
    ul {
      list-style-position: inside;

      & li div {
        display: inline-block;
      }
    }

    & ol {
      list-style-type: decimal;
    }

    & ul {
      list-style-type: disc;
    }

    & ol,
    ul,
    p,
    table,
    img,
    h4 {
      margin-top: 0.4em;
    }

    & h4 {
      font-size: 1.3em;
      font-weight: 500;
      color: $blue;
    }

    & h5 {
      color: $blue;
      font-weight: 500;
    }
  }
}

/* ABOUT US TAB */
.tab__about {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & .tab__overview-gallery li {
    @media (min-width: $md) {
      width: 33.33333%;
    }
  }

  @media (min-width: $md) {
    flex-direction: row;
  }

  &-us {
    flex: 0 0 60%;
    margin-bottom: 1.5em;

    & table {
      @extend .tab__table;
    }

    & ol,
    ul {
      list-style-position: inside;

      & li div {
        display: inline-block;
      }
    }

    & ol {
      list-style-type: decimal;
    }

    & ul {
      list-style-type: disc;
    }

    & ol,
    ul,
    p,
    table,
    img,
    h4 {
      margin-top: 0.4em;
    }

    & h4 {
      font-size: 1.3em;
      font-weight: 500;
      color: $blue;
    }

    & h5 {
      color: $blue;
      font-weight: 500;
    }
  }

  &-gallery {
    height: 500px;
    height: auto;
    overflow: auto;
    margin-top: 2rem;

    @media (min-width: $md) {
      flex-basis: 35%;
      margin-top: 0;
      height: 500px;
    }

    & ul {
      display: flex;

      @media (min-width: $md) {
        display: block;
      }
    }

    & li {
      flex: 0 0 100%;

      @media (min-width: $md) {
        flex-basis: auto;
      }
    }

    & img {
      display: block;
      width: 100%;

      @media (min-width: $md) {
        margin-bottom: 1rem;
      }
    }
  }
}

/*
* USER EDITABLE TABS
*
*/

.tab__edit-icon {
  display: inline-block;
  position: relative;
  margin-left: 0.3rem;

  & svg {
    fill: $blue;
    width: 15px;
    height: 15px;
  }

  &:hover {
    cursor: pointer;
  }
}

/* TAB CONTACT */
.tab__contact {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-info {
    padding-right: 0;
    margin-bottom: 1.5em;

    @media (min-width: $md) {
      padding-right: 1rem;
    }
  }

  &-name {
    font-weight: bold;
    padding-top: 0.5rem;

    @media (min-width: $md) {
      padding-top: 0rem;
    }
  }

  &-street,
  &-city {
    display: block;
  }

  &-phone {
    margin: 1rem 0;
    font-weight: bold;
  }

  &-phone-cut {
    display: none;
  }

  &-phone-full {
    display: none;
  }

  &-email {
    color: $blue;
    font-weight: 500;
    text-decoration: underline;
  }

  &-regular-hours {
    & dl {
      width: 250px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 2rem;

      @media (min-width: $md) {
        width: 300px;
      }
    }

    & dt,
    & dd {
      display: block;
      flex: 0 0 50%;
    }

    & dd:not(:last-child) {
      padding-right: 6px;
    }

    & dt {
      font-weight: bold;
    }
  }

  &-map {
    flex: 0 0 auto;
    align-self: center;
    margin-top: 2rem;

    @media (min-width: $md) {
      flex: 0 0 400px;
      margin-top: 0;
    }

    & img {
      display: block;
      width: 100%;
    }
  }
}

/* MAIN ACCOUNT TABS */
.tab__password,
.tab__contact-data,
.tab__credit-card {
  @extend %section;
}

/* TAB CONTACT DATA */
.tab__contact-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $xs) {
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }

  @media (min-width: $sm) {}

  &-cols {
    margin-bottom: 1.5rem;

    &>input {
      width: 100%;
    }

    &>select {
      width: 100%;
    }

    @media (min-width: $xxs) {
      flex: 0 0 45%;
    }

    @media (min-width: $md) {
      flex: 0 0 20%;
      margin-bottom: 0;
    }
  }

  & p {
    display: block;
  }

  ;

  &__street-input {
    width: 79%;
  }

  &__house-num-input {
    width: 18%;
  }
}

/* TAB PASSWORD */
.tab__password,
.tab__email {
  & h2 {
    background-color: $orange;
  }
}

.tab__credit-card {
  & h2 {
    background-color: $blue;
  }
}

.tab__archive {
  & h2 {
    background-color: $dark-grey;
  }
}

.tab__credentials {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media(min-width: $md) {
    flex-direction: row;
  }
}

.tab__credit-card {

  // border: 1px dashed violet;
  &-list {
    margin-bottom: 1rem;
  }
}

/* SEO TAB */
.tab__seo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $md) {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    justify-content: left;
    margin-top: 3.8rem;
  }

  &>div {
    margin-bottom: 1.5rem;

    @media (min-width: $md) {
      margin-bottom: 0;
    }
  }

  &-custom-url {
    flex: 0 0 20%;
  }

  &-custom-field {}

  &-description {
    flex: 0 0 50%;
  }

  &-tags {
    flex: 0 0 20%;
  }
}

/* OVERVIEW TABS */
#service-provider {
  .service-provider__tabs {
    margin-top: 2rem;
  }
}

.no-content {
  padding-top: 50px;
  font-size: 1.5em;
  padding-bottom: 20px;
  text-align: center;
}