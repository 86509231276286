%text-section {
  width: 100%;
  text-align: center;
  font-size: 90%;
  font-weight: 500;
  margin: 0 auto;
  line-height: 1.5;
  color: $dark-text;
  @media (min-width: $sm) {
    font-size: 100%;
    width: 550px;
  }
  @media (min-width: $md) {
    width: 768px;
  }
}

%text--details {
  font-size: 90%;
  line-height: 1.6;
  letter-spacing: -0.5px;
  color: $dark-text;
  @media (min-width: $md) {
    font-size: 100%;
  }
}