%btn {
  padding: 0.5rem 0.7rem;
  border: none;
  outline: none;
  transition: 0.1s all ease-in;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}

/* #ccc 3px 3px 9px 0px */
%btn__small {
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.1s all ease-in;

  &:hover {
    cursor: pointer;
    background-color: $white;
    color: $orange;
  }
}

%btn__link {
  background-color: transparent;
  color: $blue;
  font-size: 100%;
  padding: 0;
  text-align: left;
  text-decoration: underline;

  &:first-child:after {
    display: inline-block;
    margin-left: 0.2rem;
    content: '|'
  }

  &:last-child:after {
    content: ''
  }

  &:hover {
    color: lighten($blue, 10%);
  }
}

%btn__share {
  background-color: transparent;
  color: $orange;
  font-size: 110%;
  padding: 0;
  text-align: left;

  &:hover {
    color: lighten($orange, 10%);
    text-decoration: underline;
  }
}

%btn__report {
  background-color: $dark-button;
  color: $white;
  font-size: 100%;
  padding: 0.5rem;

  &:hover {
    background-color: lighten($dark-button, 10%);
  }
}

%btn--orange {
  background-color: $orange-button;
  color: $light-text;

  &:hover {
    background-color: rgba($orange-button, 0.7);
  }
}

%btn--white {
  background-color: $white;
  color: $dark-text;

  &:hover {
    background-color: darken($white, 5%);
  }
}

%btn--blue {
  background-color: $blue-button;
  color: $light-text;

  &:hover {
    background-color: rgba($blue-button, 0.7);
  }
}

%btn--grey {
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.6rem 0.8rem;
  background-color: $grey-button;
  color: $dark-text;
}

%btn--dark-grey {
  font-weight: 500;
  padding: 0.6rem 0.8rem;
  background-color: $dark-button;
  color: $light-text;

  &:hover {
    background-color: lighten($dark-button, 10%);
  }
}