  .contact-box {

    & .contact-description {

    }
    & .contact-by-wrapper {
      @extend .row;
      margin-top: 2rem;
      margin-bottom: 8rem; 
      text-align: center;
      @media(max-width: 769px) {
        font-size: 0.8em;
      }
    }
    & .contact-by {
      @extend .col-lg-6;
      & svg {
        position: relative;
        width: 35px;
        @media(max-width: 769px) {
          width: 15px;
        }
      }
      & .contact-by-label {
        font-size: 1.1em;

      }
    }
    & .contact-phone {
      & .contact-by-icon {
      }
    }
    & .contact-mail {
      & .contact-by-label {
        padding-left: 0.5em;
      }
    }
    & .contact-target {

    }
    & .contact-by-icon {
      width: 2em;
      height: 1em;
    }
  }


.contact-title {
  margin-top: 3rem;
}