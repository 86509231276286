%card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

%card__header {
  border-radius: 10px 10px 0 0;
}

%card--grey {
  background-color: $grey-box;
}

%card--dark-grey {
  background-color: $smoke-box;
}

%card__text {
  font-size: 85%;
  font-weight: 300;
  line-height: 1.5;
  color: $dark-text;

  @media (min-width: $xs) {
    font-size: 90%;
  }

  @media (min-width: $md) {
    font-size: 100%;
  }
}

%card__header--blue {
  background-color: $blue;
  padding: 10px;
}

%card__title--white {
  color: $white;
  font-size: 1.2rem;
  font-weight: 500;
}

%card__price {
  font-size: 1.5rem;
  letter-spacing: 1px;
  padding: 1.5rem 0;
  color: $blue;

  @media (min-width: $sm) {
    font-size: 1.2rem;
    letter-spacing: 0;
    padding: 1rem 0;
  }

  @media (min-width: $md) {
    font-size: 1.5rem;
    letter-spacing: 1px;
    padding: 1.5rem 0;
  }
}

%box {
  padding: 1rem;

  @media (min-width: $md) {
    padding: 2rem;
  }
}

%shadow {
  box-shadow: #ccc 3px 3px 9px 0px;
}