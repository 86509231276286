%section {
  margin-bottom: 2.5rem;
  @media (min-width: $xs) {
    margin-bottom: 3rem;
  }
  @media (min-width: $lg) {
    margin-bottom: 5rem;
  }
}

%section--narrow {
  margin-bottom: 1rem;
  @media (min-width: $xs) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: $lg) {
    margin-bottom: 2rem;
  }
}