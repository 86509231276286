.bcrumb {
  @extend .container;
  padding-top: 55px;
  margin-bottom: 3rem;
  @media (min-width: $md) {
    padding-top: 290px;
  }
}

.bcrumb-list {
  padding-left: 0;
}
.bcrumb-list-item {
  display: inline-block;
  font-weight: 400;
  font-size: 90%;

  &:after {
    position: relative;
    top: 2px;
    display: inline-block;
    content: '\203A';
    font-size: 180%;
    font-weight: 500;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  &:last-child:after {
    content: '';
  }
}

.bcrumb-list-item svg {
  position: relative;
  top: 2px;
  fill: $black;
  width: 15px;
  height: 15px;
}