.cookie {
  display: block;
  width: 100%;
  padding: 13px 0;
  position: fixed;
  bottom: -100%;
  opacity: 0;
  // bottom: 0;
  // opacity: 1;
  z-index: 1000;
  background-color: $orange-box;
  color: #fff;
  transition: all 0.5s ease-in-out;
  @media (min-width: $lg) {
    display: flex;
  }
}

.cookie__wrapper {
  position: relative;
  display: block;
  @media (min-width: $md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cookie__action {
  text-align: center;
  margin-top: 1rem;
  @media (min-width: $md) {
    flex-basis: 200px;
    margin-top: 0;
    text-align: right;
  }
}

.cookie__button {
  @extend %btn;
  @extend %btn--grey;
  width: 100%;
  @media (min-width: $md) {
    width: auto;
    }
}

.cookie__close {
  position: absolute;
  top: 0;
  right: 0.9rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: $white;
  @media (min-width: $md) {
  position: relative;
  top: 0.2rem;
  left: 0;
  margin-left: 0.5rem;
  }
}

.cookie__text {
  width: 90%;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 1.3;
  @media (min-width: $md) {
    flex-basis: 700px;
  }
  @media (min-width: $lg) {
    flex-basis: auto;
  }
}

.cookie--shown {
  bottom: 0;
  opacity: 1;
}
