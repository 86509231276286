/* Colors */
$white: #fff;
$lightest-grey: #f5f5f5;
$light-grey: #ededed;
$grey: #d5d5d5;
$strong-grey: #676767;
$dark-grey: #404040;
$black: rgb(27, 27, 27);
$blue: #4b78a5;
$orange: #fa8526;
$red: #cd1714;
$green: #38ab32;

/* Boxes */
$dark-box: $dark-grey;
$smoke-box: $grey;
$grey-box: $light-grey;
$blue-box: $blue;
$orange-box: $orange;

/* Text */
$black-text: $black;
$dark-text: $strong-grey;
$light-text: $white;
$grey-text: $light-grey;
$orange-text: $orange;

/* Headings */
$white-heading: $white;
$orange-heading:$orange;
$grey-heading: $dark-grey;
$blue-heading: $blue;

/* Links */
$white-link: $white;
$grey-link: $grey;
$orange-link: $orange;

/* Icons */
$grey-icon: $grey;

/* Buttons */
$orange-button: $orange;
$blue-button: $blue;
$grey-button: $light-grey;
$dark-button: $dark-grey;

/****************/
/* Break points */
/************** */
$xxs: 400px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1440px;
$xxl: 1600px;